/**
 * You must register every widget here after it is constructed
 */

const   WIDGET_STACK_NAME               =   "WIDGET_STACK";

export function WidgetStack_InitStorage(){
    window[ WIDGET_STACK_NAME ]         =   new WeakMap();
}

export function WidgetStack_CreateWidget( widgetClass, widgetSelector, storeJQuery = false, pfnOnWidgetConstructSuccess = undefined  ){
    if  ( typeof widgetClass !== 'function' ){
        throw                           new Error( `WidgetStack_CreateWidget : widgetClass must be a proper class or class constructor!` );
    }
    var lastWidget                      =   undefined;
    function _createWidget( element ){
        if  ( window[ WIDGET_STACK_NAME ].has( element ) ){
            return;
        }
        let component                   =   undefined;
        try{
            component                   =   new widgetClass( element );
        } catch( e ){
            console.debug( `WidgetStack_CreateWidget : can not construct class named ${widgetClass.name} because of exception`, e );
        }
        if  ( component ){
            if  ( typeof storeJQuery === 'string' ){
                $( element ).data( storeJQuery, component );
            }
            window[ WIDGET_STACK_NAME ].set( element, component );
            console.debug( `WidgetStack_CreateWidget : bound ${ widgetClass.name } on`, element );
        }
        if  ( typeof pfnOnWidgetConstructSuccess === 'function' ){
            pfnOnWidgetConstructSuccess( component );
        }
        return                          ( lastWidget = component );
    }
    if  ( typeof widgetSelector === 'string' ){
        document.querySelectorAll( widgetSelector ).forEach( ( element ) => {
            lastWidget                  =   _createWidget( element );
        } );
    } else {
        lastWidget                      =   _createWidget( widgetSelector );
    }
    return                              lastWidget;
}

export function WidgetStack_GetList(){
    return                              window[ WIDGET_STACK_NAME ];
}

/**
 * Gets an attached widget from element
 * @param {HTMLElement|string} element actual element or selector to query
 * @param {?string} attached if it is not empty, tries to get appropriate data piece from jQuery
 * @returns {Class|null} an instance of bound class
 * @throws {Error} exception if no associated is found
 */
export function WidgetStack_GetWidget( element, attached = undefined ){
    if  ( typeof element === 'string' ){
        if  ( !( element = document.querySelector( element ) ) ){
            throw                           new Error( `WidgetStack_GetWidget : no element to use` );
        }
    }
    if  ( !( element instanceof HTMLElement ) ){
        throw                           new Error( `WidgetStack_GetWidget : element is not instance of HTMLElement!` );
    }
    return                              attached ? 
        $( element ).data( attached ) : 
        window[ WIDGET_STACK_NAME ].has( element ) ? window[ WIDGET_STACK_NAME ].get( element ) : null;
}