import { ArticleViewHelperClass } from "./articleViewHelperClass";

export class ArticleWidgetClass extends ArticleViewHelperClass{

    __getViewId(){
        return                          "ArticleWidgetClass";
    }

    __getSelectors(){
        let isPopularWidget             =   Array.isArray( this._extraTags ) ? this._extraTags.includes( "widget" ) : false;
        return                          ( isPopularWidget ) ? { 
            inser                               :   ".answers-list__wrap",
            tableItem                           :   "div"
        } :                             {
            inser                               :   ".articles-listing-main__slider",
            tableItem                           :   "a.articles__item-normal",
        }
    }

    __getDynamicSelectors(){
        return                          [
            "inser"
        ];
    }

    _initSlider(){
        if  ( this._$inser.hasClass( "js-init--articles") ){
            this._$inser.slick( {
                arrows: false,
                slidesToShow: 4,
                slidesToScroll: 2,
                dots: true,
                appendDots: ".js-init--articles-dots",
                responsive: [
                    {
                        breakpoint: 900,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            //variableWidth: true,
                            appendDots: ".js-init--articles-dots--mobile"
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            //variableWidth: true,
                            appendDots: ".js-init--articles-dots--mobile"
                        }
                    },
                    {
                        breakpoint: 550,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            //variableWidth: true,
                            appendDots: ".js-init--articles-dots--mobile"
                        }
                    }
                ]
            } );
        }
        return                          true;
    }

    _afterConstruction(){
        if  ( super._afterConstruction() ){
            this._initSlider();
            return                      true;
        }
        return                          false;
    }

}