const $ = window.$;
import { range } from "../common/commonHelpers";
import {
    API_KEY_QUERY,
    API_KEY_QUERY_MODE,
    API_KEY_QUERY_BLOCKS,
    API_KEY_QUERY_ADDITIONAL,
    API_KEY_CURRENT_STATE,
    API_KEY_VIEW_MODE,
    API_KEY_ITEMS,
    API_QUERY_MODE_SEARCH,
    API_QUERY_MODE_SORT,
    API_QUERY_MODE_PAGE,
    API_QUERY_MODE,
} from "../common/payloadBuilder";

export const BLOCK_NAME_SEARCH          =   "search";

/** constants for view */
export const PAGINATOR_MODE_KNP         =   "knp";
export const PAGINATOR_MODE_ARRAY       =   "array";

/** cookie */
export const PAGE_CHANGE_COOKIE_NAME    =   "PageChange";

// 25.02.2023 fully workable! ( stable version must be used! )

export class AbstractListViewHelper{

    _showDebugData                      =   false;

    _viewId                             =   "AbstractListViewHelper";
    _blockId                            =   undefined;

    /**
     * @type {JQuery<HTMLFormElement>}
     */
    _$form                              =   undefined;

    /**
     * @type {JQuery<HTMLElement>}
     */
    _$table                             =   undefined;
    /**
     * @type {JQuery<HTMLElement>}
     */
    _$header                            =   undefined;
    /**
     * @type {JQuery<HTMLElement>}
     */
    _$inser                             =   undefined;
    /**
     * @type {JQuery<HTMLElement>}
     */
    _$footer                            =   undefined;
    /**
     * @type {JQuery<HTMLElement>}
     */
    _$footerMoreButton                  =   undefined;
    /**
     * @type {JQuery<HTMLElement>}
     */
    _$footerPagination                  =   undefined;
    /**
     * @type {JQuery<HTMLElement>}
     */
    _$hiddenZone                        =   undefined;

    _url                                =   undefined;
    _method                             =   "POST";

    _fromSubView                        =   undefined;
    _renderOnEmpty                      =   undefined;

    _selectors                          =   {
        header                              :   undefined,
        inser                               :   undefined,
        footer                              :   undefined,
        footerMoreButton                    :   undefined,
        footerPagination                    :   undefined,
        tableItem                           :   undefined,
        tableAdClass                        :   undefined,
        footerPaginationItem                :   undefined,
        headerSortButtonClass               :   undefined
    };

    _staticSelectors                    =   undefined;
    _dynamicSelectors                   =   undefined;

    _handlebars                         =   {
        search                              :   undefined,
        sort                                :   undefined,
        ad                                  :   undefined,
        pagination                          :   undefined,
    };

    _handlebarsDisableCompilation       =   [ "ad" ];

    _handlebarsSupported                =   false;

    _translation                        =   {
        dictionary                          :   null,
        fields                              :   null
    };

    _translationSupported               =   false;

    _sort                               =   {
        enable                              :   false,
        sort_order                          :   undefined,
        order_by                            :   undefined,
    };

    _sortSupported                      =   false;

    /**
     * current query filter
     */
    _currentFilter                      =   undefined;

    /**
     * filter which was sent just now
     */
    _sentFilter                         =   undefined;

    /**
     * data id selector
     */
    _datamapDataId                      =   "itemId";

    /**
     * advertisement data
     */
    _advertisement                      =   {
        /**
         * every x items
         */
        every                               :   undefined,
        /**
         * maximum ad slots
         */
        max                                 :   undefined,
        /**
         * current ad count
         */
        curr                                :   undefined,
        /**
         * last visible ad index
         */
        index                               :   undefined,
        /**
         * do show on last item?
         */
        last                                :   undefined,
        /**
         * base ( when loaded - base index of last item in current view )
         */
        base                                :   undefined,
        /**
         * template to use ( string )
         */
        template                            :   undefined
    };

    _advertisementSupported             =   false;

    /**
     * extra tags
     */
    _extraTags                          =   undefined;

    /**
     * paginator state
     */
    _paginatorState                     =   {
        paginatorMode                       :   "array",
        pagesLoaded                         :   {},
        /** Total page count for this state */
        pagesCount                          :   0,
        /** Total items count for this state ( including non-visible and/or not loaded ) */
        itemsCount                          :   0,
        /** Currently available for client */
        itemsLoaded                         :   0,
        /** Number of items per page */
        itemsPerPage                        :   0,
        /** Current page number which was loaded last time */
        pageCurrent                         :   0,
        pageParameter                       :   "page",
        /** List of items in view ( all, including sub-levels ) */
        itemsInView                         :   [],
        /** List of items excluding sub-levels ( if any ) */
        itemsInViewTopLevel                 :   [],
    };

    _paginatorSupport                   =   false;

    _paginationInplace                  =   false;

    _slotId                             =   null;

    _extraTemplateParameters            =   null;

    // #region event declarations

    /**
     * user function to build more payload
     *
     * @type { function( listClassInstance, { next_page : Number, max_amount : Number, page_key : string }, currentFilter ) : {
     *  next_page : Number,
     *  max_amount : Number,
     *  page_key : string
     * } }
     */
    _pfnOnBuildMorePayload              =   undefined;

    /**
     * user function to build sort payload
     *
     * @type { function( listClassInstance, { order_by : String, sort_order : String }, currentFilter ) : {
     *  order_by : String,
     *  sort_order : String
     * } }
     */
    _pfnOnBuildSortPayload              =   undefined;

    /**
     * user function to build search payload
     *
     * @type { function( listClassInstance, currentFilter ) : currentFilter }
     */
    _pfnOnBuildSearchPayload            =   undefined;

    /**
     * @type { function( listClassInstance, payloadClient ) : Boolean }
     */
    _pfnOnBeforeSendSortinFilter        =   undefined;

    /**
     * @type { function( listClassInstance, payloadClient ) : Boolean }
     */
    _pfnOnBeforeSendPageFilter          =   undefined;

    /**
     * @type { function( listClassInstance, payloadClient ) : Boolean }
     */
    _pfnOnBeforeSendSearchFilter        =   undefined;

    /**
     * @type { function( listClassInstance, isSuccess, payloadClient, isStart ) : Boolean }
     */
    _pfnOnAfterSendSortinFilter         =   undefined;

    /**
     * @type { function( listClassInstance, isSuccess, payloadClient, isStart ) : Boolean }
     */
    _pfnOnAfterSendPageFilter           =   undefined;
    /**
     * @type { function( listClassInstance, isSuccess, payloadClient, isStart ) : Boolean }
     */
    _pfnOnAfterSendSearchFilter         =   undefined;

    /**
     * @type { function( JQuery.Event ) }
     * this event has two new fields ( .page and .max_amount )
     */
    _pfnOnMoreButtonClick               =   undefined;

    /**
     * @type { function( JQuery.Event ) }
     */
    _pfnOnSearchButtonClick             =   undefined;

    /**
     * @type { function( JQuery.Event ) }
     */
    _pfnOnSortButtonClick               =   undefined;

    /**
     * @type { function( listClassInstance, blockKey, blockData ) }
     */
    _pfnOnBlockReceived                 =   undefined;

    // #endregion

    __getViewId(){
        return                          this._viewId;
    }

    __getSelectors( selector = undefined ){
        return                          selector ? 
            ( this._selectors[ selector ] ?? null ) :
            this._selectors;
    }

    __getDynamicSelectors(){
        return                          this._dynamicSelectors;
    }

    get showDebugData(){
        return                          this._showDebugData;
    }

    constructor( $table ){
        if  ( this.showDebugData ){
            console.debug( `AbstractListViewHelper::constructor : start` );
        }
        this._$table                    =   $table;
    }

    // #region event setters & getters

    setOnBeforeSendSortingFilter( event ){
        this._pfnOnBeforeSendSortinFilter  =   ( typeof event === 'function' ) ? event : null;
    }

    getOnBeforeSendSortingFilter(){
        return                          this._pfnOnBeforeSendSortinFilter;
    }

    setOnBeforeSendSearchFilter( event ){
        this._pfnOnBeforeSendSearchFilter  =   ( typeof event === 'function' ) ? event : null;
    }

    getOnBeforeSendSearchFilter(){
        return                          this._pfnOnBeforeSendSearchFilter;
    }

    setOnAfterSendSortingFilter( event ){
        this._pfnOnAfterSendSortinFilter  =   ( typeof event === 'function' ) ? event : null;
    }

    getOnAfterSendSortingFilter(){
        return                          this._pfnOnAfterSendSortinFilter;
    }

    setOnAfterSendSearchFilter( event ){
        this._pfnOnAfterSendSearchFilter  =   ( typeof event === 'function' ) ? event : null;
    }

    getOnAfterSendSearchFilter(){
        return                          this._pfnOnAfterSendSearchFilter;
    }

    setOnBlockReceived( event ){
        this._pfnOnBlockReceived        =   ( typeof event === 'function' ) ? event : null;
    }

    getOnBlockReceived( event ){
        return                          this._pfnOnBlockReceived;
    }

    setOnMoreButtonClick( event ){
        this._pfnOnMoreButtonClick      =   ( typeof event === 'function' ) ? event : null;
    }

    getOnMoreButtonClick( event ){
        return                          this._pfnOnMoreButtonClick;
    }

    // #endregion

    getForm(){
        return                          this._$form;
    }

    //#region  Initializator internals

    _resolveApiLink( link ){
        return                          ( typeof link === 'string' ) ? Routing.generate( link ) : null;
    }

    _initForm(){
        this._$form                     =   this._$table[ 0 ]?.dataset?.form ?? null;
        if  ( this._$form ){
            if  ( !( this._$form = $( `[name=${this._$form}]` ) ).length ){
                this._$form             =   undefined;
            } else {
                if  ( !this._url ){
                    this._url           =   this._$form[ 0 ].action;
                    this._method        =   this._$form[ 0 ].method;
                }
            }
        }

    }

    /**
     * @param {String} data
     * @returns
     */
    _handlebarsCompileIndividual( data ){
        return                          ( data ) ?  Handlebars.compile( data ) : undefined;
    }

    _handlebarsInit(){
        this._handlebarsSupported       =   false;
        if  ( typeof this._handlebars !== "object" )
            return                      false;
        let handlebarsDatamap           =   this._$table[ 0 ]?.dataset?.handlebars;
        this._handlebarsSupported       =   0;
        if  ( handlebarsDatamap ){
            let handlebarsKeys          =   Object.keys( this._handlebars );
            let handlebarsDisable       =   Array.isArray( this._handlebarsDisableCompilation ) ? this._handlebarsDisableCompilation : null;
            handlebarsDatamap           =   Object.fromEntries( Array.from( Object.entries( JSON.parse( handlebarsDatamap ) ) ).filter( ( [ key, value ] ) => {
                return                  handlebarsKeys.includes( key ) && ( typeof value === 'string' );
            } ) );
            if  ( Object.entries( handlebarsDatamap ).length > 0 ){
                Object.entries( handlebarsDatamap ).forEach( ( [ key, value ] ) => {
                    let htmlData         =   `[type="text/x-handlebars-template"][data-block-id="${this._blockId}"][data-template-name="${key}"][data-template-id="${value}"]`;
                    if  ( ( htmlData = $( htmlData ) ).length ){
                        this._handlebars[ [ key ] ] =   htmlData.html();
                        if  ( handlebarsDisable && !handlebarsDisable.includes( key ) ){
                            this._handlebars[ [ key ] ] =   this._handlebarsCompileIndividual( this._handlebars[ [ key ] ] );
                        }
                        this._handlebarsSupported++;
                    } else {
                        this._handlebars[ [ key ] ] = undefined;
                    }
                } );
            } else {
                handlebarsDatamap       =   null;
            }
            delete                      this._$table[ 0 ]?.dataset?.handlebars;
        }
        this._handlebarsSupported       =   this._handlebarsSupported > 0;
    }

    _translationInit(){
        this._translationSupported      =   false;
        if  ( typeof this._translation !== "object" )
            return                      false;
        let translation                 =   this._$table[ 0 ]?.dataset?.translation;
        if  ( translation ){
            translation                 =   JSON.parse( translation );
            if  ( translation && !translation.hasOwnProperty( "dictionary" ) ){
                if  ( this._showDebugData ){
                    console.debug( `${ this.__getViewId() }::_translationInit : although translation filed is present, it has no dictionary!` );
                }  else {
                    console.debug( `${ this.__getViewId() }::_translationInit : dictionary ok!` );
                }
            } else {
                if  ( !translation.hasOwnProperty( "fields" ) ){ // restore
                    translation.fields  =   null;
                }
            }
            this._translation           =   translation;
            this._translationSupported  =   ( this._translation !== null );
            // if  ( !translation.hasOwnProperty( "dictionary" ) || !translation.hasOwnProperty( "fields" ) ){
            //     return;
            // } else {
            //     this._translation       =   translation;
            //     this._translationSupported  =   true;
            // }
            delete                      this._$table[ 0 ]?.dataset?.translation;
        }
    }

    _sortInit(){
        this._sortSupported             =   false;
        if  ( typeof this._sort !== "object" )
            return                      false;
        let sort                        =   this._$table[ 0 ]?.dataset?.sort;
        if  ( sort ){
            sort                        =   JSON.parse( sort );
            if  ( !sort.hasOwnProperty( "enable" ) || !sort.hasOwnProperty( "sort_order" ) || !sort.hasOwnProperty( "order_by" ) ){
                return;
            } else {
                this._sort              =   sort;
                this._sortSupported     =   true;
            }
            delete                      this._$table[ 0 ]?.dataset?.sort;
        }
    }

    _filterInit(){
        this._currentFilter             =   this._$table[ 0 ]?.dataset?.filter ?? null;
        if  ( this._currentFilter )
            this._currentFilter         =   JSON.parse( this._currentFilter );
        delete                          this._$table[ 0 ]?.dataset?.filter;
    }

    /**
     * internal advertiser
     */
    _advertisementInit(){
        this._advertisementSupported    =   false;
        let advertisement               =   this._$table[ 0 ]?.dataset?.advertisement ?? null;
        if  ( advertisement ){
            this._advertisement         =   Object.assign( {}, this._advertisement, JSON.parse( advertisement ) );
            if  ( !this._advertisement.max ){
                this._advertisement.curr    =   this._paginatorState.itemsLoaded;
                this._advertisement.index   =   this._paginatorState.itemsLoaded - 1;
            } else {
                this._advertisement.curr    =   Math.floor( this._paginatorState.itemsLoaded / this._advertisement.every );
                if  ( this._advertisement.curr >= this._advertisement.max ){
                    this._advertisement.curr    =   this._advertisement.max;
                }
                this._advertisement.index   =   ( ( this._advertisement.curr * this._advertisement.every ) - 1 ) ?? null ;
                if  ( !isNaN( this._advertisement.index ) && this._advertisement.index < 0 ){
                    this._advertisement.index    =   null;
                }
            }
            this._advertisementSupported    =   true;
            this._advertisement.base        =   this._paginatorState.itemsLoaded - 1;
            delete                      this._$table[ 0 ]?.dataset?.advertisement;
        }
    }

    /**
     * extra tags, slots and extra view template parameters
     */
    _extraTagsInit(){
        this._extraTags                     =   this._$table[ 0 ].dataset?.extraTags ?? undefined;
        if  ( this._extraTags ){
            try{
                this._extraTags             =   JSON.parse( this._extraTags );
            } catch ( e ) {
                this._extraTags             =   undefined;
            }
            delete                          this._$table[ 0 ].dataset?.extraTags;
        }
        if  ( this._slotId = this._$table.attr( 'data-slot-id' ) ){
            this._$table.attr( 'data-slot-id', null );
        };
        if  ( this._extraTemplateParameters = this._$table.attr( 'data-extra-template-parameters' ) ){
            this._$table.attr( 'data-extra-template-parameters', null );
        };
    }

    /**
     * initializes sub view data
     */
    _initSubViewData(){
        this._fromSubView                   =   this._$table[ 0 ].dataset?.subView === "1";
        this._renderOnEmpty                 =   this._$table[ 0 ].dataset?.renderOnEmpty === "1";
        if  ( this._$table[ 0 ].dataset.hasOwnProperty( "subView" ) ){
            delete  this._$table[ 0 ].dataset.subView;
        }
        if  ( this._$table[ 0 ].dataset.hasOwnProperty( "renderOnEmpty" ) ){
            delete  this._$table[ 0 ].dataset.renderOnEmpty;
        }
    }

    /**
     * initializes paginator data for this table
     * @returns
     */
    _paginatorInit(){
        this._paginatorSupport          =   false;
        let paginator                   =   this._$table[ 0 ]?.dataset?.pagination ?? null;
        if  ( paginator ){
            this._paginatorState        =   Object.assign( {}, this._paginatorState, JSON.parse( paginator ) );
            this._paginatorSupport      =   true;
            delete                      this._$table[ 0 ]?.dataset?.pagination;
        }
    }

    /**
     * recalculates & hides items to hidden zone if in array mode
     */
    _paginatorArrayModeRecalc(){
        if  ( !this._paginatorSupport || this._paginatorState?.paginatorMode !== PAGINATOR_MODE_ARRAY ){
            return;
        }
        this._$hiddenZone               =   this._$table.find( '.tablemax__hidden' );
        if  ( !this._$hiddenZone.length ){
            this._$hiddenZone           =   $( '<div class="tablemax__hidden"></div>' );
            this._$table.append( this._$hiddenZone );
        }
        this._paginatorState.pageCurrent    =   1; // always drop to first page
        // let pageNumber                  =   this._paginatorState.pageCurrent = 1; // always drop to first page
        // if  ( isNaN( pageNumber ) ){
        //     throw                       new Error( `${ this.__getViewId() }::_paginatorArrayModeRecalc : no current page is present here` );
        // }
        let items                       =   this._$inser?.children( this.__getSelectors().tableItem ) ?? [];

        if  ( this.showDebugData ){
            console.debug( `${ this.__getViewId() }::_paginatorArrayModeRecalc : before - current items in view are`, items, `planning to view ${this._paginatorState.itemsPerPage} items per page` );
        }
        if  ( !items.length ){
            return;
        }
        items.slice( this._paginatorState.pageCurrent * this._paginatorState.itemsPerPage ).detach().appendTo( this._$hiddenZone );
    }

    /**
     * Gets if page content already loaded
     */
    _checkPageLoaded( pageNum ){
        if  ( this._paginatorState.pagesLoaded?.hasOwnProperty( pageNum )  )
            return                      this._paginatorState.pagesLoaded[ pageNum ];
        else
            return                      false;
    }

    _selectorsInit( selectors ){
        if  ( !Array.isArray( selectors ) ){
            selectors                   =   Object.keys( this.__getSelectors() );
        }
        if  ( !selectors.length ){
            if  ( this.showDebugData ) {
                console.debug( `${ this.__getViewId() }::_selectorsInit : no selectors to initialize!` );
            }
            return;
        } else {
            console.log( `${ this.__getViewId() }::_selectorsInit : selectors are `, selectors );
        }
        if  ( this.showDebugData ){
            var foundSelectors          =   [];
            var missingSelectors        =   [];
            var invalidSelectors        =   [];
        }
        selectors.forEach( ( value ) => {
            let property                =   "_$" + value;
            if  ( !this.hasOwnProperty( property ) ){
                if  ( this.showDebugData ){
                    invalidSelectors.push( property );
                }
            } else {
                if  ( !( this[ property ] = this._$table.find( this.__getSelectors()[ value ] ) ).length ){
                    if  ( this.showDebugData ){
                        missingSelectors.push( this.__getSelectors()[ value ] );
                    }
                    this[ property ]    =   null;
                } else {
                    if  ( this.showDebugData ){
                        foundSelectors.push( value );
                    }
                }
            }
        } );
        if  ( this.showDebugData) {
            if  ( invalidSelectors.length ){
                console.debug( `${ this.__getViewId() }::_selectorsInit : invalid selectors`, invalidSelectors );
            }
            if  ( missingSelectors.length ){
                console.debug( `${ this.__getViewId() }::_selectorsInit : missing selectors`, missingSelectors );
            }
            if  ( foundSelectors.length ){
                console.debug( `${ this.__getViewId() }::_selectorsInit : found selectors`, foundSelectors );
            }
        }
    }

    //#endregion

    _afterConstruction(){
        if  ( typeof this._datamapDataId !== 'string' ){
            throw                       new Error( `${ this.__getViewId() }::_afterConstruction : _datamapDataId is not specified` );
        }
        if  ( !this._$table ){
            throw                       new Error( `${ this.__getViewId() }::_afterConstruction : _$table is not specified` );
        }
        this._extraTagsInit();
        this._selectorsInit( this.__getDynamicSelectors() );
        this._blockId                   =   this._$table[ 0 ]?.dataset?.blockId;
        this._url                       =   this._resolveApiLink( this._$table[ 0 ]?.dataset?.api ?? null );
        this._initForm();
        if  ( this._url ){
            this._handlebarsInit();
            this._translationInit();
            this._sortInit();
            this._filterInit();
        }
        this._paginatorInit();
        this._advertisementInit();
        this._initSubViewData();
        if  ( this.showDebugData ){
            console.debug( `${this.__getViewId()}::_afterConsturtion : _url is ${this._url}` );
            console.debug( `${this.__getViewId()}::_afterConsturtion : _handlebars`, this._handlebars );
            console.debug( `${this.__getViewId()}::_afterConsturtion : _translation`, this._translation );
            console.debug( `${this.__getViewId()}::_afterConsturtion : _paginatorState`, this._paginatorState );
            console.debug( `${this.__getViewId()}::_afterConsturtion : _advertisement`, this._advertisement );
            console.debug( `${this.__getViewId()}::_afterConsturtion : _currentFilter`, this._currentFilter );
            console.debug( `${this.__getViewId()}::_afterConsturtion : _sort`, this._sort );
            console.debug( `${this.__getViewId()}::_afterConsturtion : _extraTags`, this._extraTags );
        }
        this._paginatorArrayModeRecalc();
        this._calculateItemsInView();
        this._bindEvents();
        this._checkAfterPageLoaded();
        return                          true;
    }

    _bindEvents(){
        if  ( this.showDebugData ){
            var boundEvents             =   [];
        }
        let selectors                   =   this.__getSelectors();
        if  ( selectors.header && selectors.headerSortButtonClass ){
            this._$table.on( "click", selectors.header + " " + selectors.headerSortButtonClass, ( this._onSortButtonClick ).bind( this ) );
            if  ( this.showDebugData ){
                boundEvents.push( "_onSortButtonClick" );
            }
        }
        if  ( selectors.footer ){
            if  ( selectors.footerMoreButton ){
                this._$table.on( "click", selectors.footer + " " + selectors.footerMoreButton, ( this._onMoreButtonClick ).bind( this ) );
                if  ( this.showDebugData ){
                    boundEvents.push( "_onMoreButtonClick" );
                }
            }
            if  ( selectors.footerPagination && selectors.footerPaginationItem ){
                this._$table.on( "click", selectors.footer + " " + selectors.footerPagination + " " + selectors.footerPaginationItem, ( this._onPageButtonClick ).bind( this ) );
                if  ( this.showDebugData ){
                    boundEvents.push( "_onPageButtonClick" );
                }
            }
        }
        if  ( this.showDebugData ){
            console.debug( `${ this.__getViewId() }::_bindEvents : bound events`, boundEvents );
        }
    }

    /**
     * Get this._datamapDataId from specified element
     *
     * @param {*} element
     * @returns
     */
    __getItemId( element ){
        let itemId                      =   element.dataset.hasOwnProperty( this._datamapDataId ) ? element.dataset[ this._datamapDataId ] : undefined;
        return                          isNaN( itemId ) ? null : Number( itemId );
    }

    /**
     * inserts a content to valid spot respecting a page number
     */
    __performContentInsert( content, pageNum ){
        if  ( pageNum == 1 ){ // insert to beginning
            this._$inser.prepend( content );
        } else { // find last loaded page which is smaller than we want
            let validSpot               =   0;
            for ( let i of range( pageNum - 1, 1 ) ){
                if  ( this._paginatorState.pagesLoaded[ [ i ] ] ){
                    validSpot           =   i;
                    break;
                }
            }
            if  ( validSpot ){
                $( content ).insertAfter( this._$inser.children( `[data-paginator-page-num='${validSpot}']:last` ) );
            } else { // no loaded before was found, prepend
                this._$inser.prepend( content );
            }
        }
    }

    /**
     * @returns {undefined|JQuery<HTMLElement>} a list of items in view as JQuery object, undefined if there is selector error
     */
    _getItemsInView( topLevelOnly = true, onlyWihoutPageMarks = false ){
        let selector                    =   `${this.__getSelectors( 'tableItem' )}[data-item-id]`;
        if  ( onlyWihoutPageMarks ){
            selector                    =   selector + ':not([data-paginator-page-num])';
        }
        return                          topLevelOnly ?
            this._$inser?.children( selector ) :
            this._$inser?.find( selector )
        ;
    }

    _assignPageNumber( pageNum ){
        let nonAssigned                 =   this._getItemsInView( true, true );
        if  ( nonAssigned !== null ){
            nonAssigned.attr( 'data-paginator-page-num', pageNum );
        }
    }

    /**
     *  updates inner state of view.
     *  @returns bool
     */
    _calculateItemsInView(){
        let itemSelector                =   undefined;
        let selectors                   =   this.__getSelectors();
        if  ( selectors.tableAdClass )
            itemSelector                =   `${selectors.tableItem}:not(.${selectors.tableAdClass})`;
        else
            itemSelector                =   `${selectors.tableItem}`;
        this._paginatorState.itemsInView            =   ( this._$inser ) ? this._$inser.find( itemSelector ).map( ( function( i, el ) {
            return this.__getItemId( el );
        } ).bind( this ) ).toArray() : [];
        this._paginatorState.itemsInViewTopLevel    =   ( this._$inser ) ? this._$inser.children( itemSelector ).map( ( function( i, el ) {
            if  ( !el.dataset.hasOwnProperty( "paginatorPageNum" ) )
                el.dataset.paginatorPageNum         =   this._paginatorState.pageCurrent;
            return this.__getItemId( el );
        } ).bind( this ) ).toArray() : [];
        if  ( this.showDebugData ){
            console.debug( `${ this.__getViewId() }::calculateItemsInView : view count is ${this._paginatorState.itemsInView.length}` );
        }
        return                          this._paginatorState.itemsInView;
    }

    _paginatorUpdate_SetCurrentPage( currentPage ){
        if  ( ( this._paginatorState.pagesLoaded[ currentPage ] ?? undefined ) === undefined )
            return                      null;
        this._paginatorState.pageCurrent    =   currentPage;
        if  ( this.showDebugData ){
            console.debug( `${ this.__getViewId() }::_paginatorUpdate_SetCurrentPage : current page is set to ${currentPage}` );
        }
    }

    /**
     * updates the paginator when we got next page from response or updates total count of pages
     * @param {Number} receivedPage
     * @param {Number} pagesCount
     * @param {Number} itemsCount
     * @param {Number} totalItemsCount
     * @param {Boolean} doRewriteCurrentState
     * @returns
     */
    _paginatorUpdate( receivedPage = null, pagesCount = null, itemsCount = null, itemsLoaded = null, doRewriteCurrentState = false ){
        if  ( doRewriteCurrentState && !isNaN( pagesCount ) && ( pagesCount !== null ) ){
            this._paginatorState.pagesCount = Number( pagesCount );
            this._paginatorState.pagesLoaded = {};
            for ( let i = 1; i <= this._paginatorState.pagesCount; i++ ){
                this._paginatorState.pagesLoaded[ [[ i ]] ] = false;
            }
        }
        if  ( doRewriteCurrentState && !isNaN( itemsCount ) && ( itemsCount !== null ) ){
            this._paginatorState.itemsCount =   Number( itemsCount );
        }
        if  ( doRewriteCurrentState && !isNaN( itemsLoaded ) && ( itemsLoaded !== null ) ){
            this._paginatorState.itemsLoaded    =   Number( itemsLoaded );
        }
        let currentPage = NaN;
        if  ( doRewriteCurrentState && !isNaN( receivedPage ) && ( receivedPage !== null ) ){
            this._paginatorState.pageCurrent    =   Number( receivedPage );
        }
        currentPage                     =   this._paginatorState.pageCurrent;
        if  ( this._paginatorState.pagesLoaded.hasOwnProperty( currentPage ) ){
            this._paginatorState.pagesLoaded[ [[currentPage]] ] = true;
        }
        if  ( this.showDebugData ){
            console.debug( `${ this.__getViewId() }::paginatorUpdate : paginator data`, this._paginatorState );
        }
    }

    /**
     * returns next page number to load ( null if all is loaded or no pages )
     */
    _paginatorSelectNextPage(){
        let currentPage                 =   this._paginatorState.pageCurrent;
        if  ( !currentPage ){
            throw                       new Error( `${ this.__getViewId() }::_paginatorSelectNextPage : no current page is set.` );
        }
        let pagesIds                    =   Object.keys( this._paginatorState.pagesLoaded );
        pagesIds                        =   [].concat( pagesIds.slice( currentPage ), pagesIds.slice( 0, currentPage - 1 ) );
        for ( let i = 0; i < pagesIds.length; i++ ){
            if  ( !this._paginatorState.pagesLoaded[ [pagesIds[ i ]] ] ){
                return                  Number( pagesIds[ i ] );
            }
        }
        return                          null;
    }

    _checkAfterPageLoaded(){
        if  ( this._paginatorState.itemsInViewTopLevel.length >= this._paginatorState.itemsCount ){
            if  ( ( this._paginatorState.pagesCount > 1 ) && this._$footerPagination ) {
                if  ( this._$footerMoreButton ){
                    this._$footerMoreButton.hide();
                }
            } else {
                if  ( this._$footer ){
                    this._$footer.hide();
                }
            }
        } else if ( this._$footerMoreButton && this._$footerMoreButton.is( ":hidden" ) ){
            this._$footerMoreButton.show();
            if  ( this._$footer.is( ":hidden" ) ){
                this._$footer.show();
            }
        }
        if  ( this.showDebugData ){
            console.debug( `${ this.__getViewId() }::_checkAfterPage loaded done` );
        }
    }

    _advertisementReset(){
        Object.assign( this._advertisement, {
            curr                            :   0,
            index                           :   undefined,
            base                            :   undefined
        } );
    }

    //#region Prepare payload

    /**
     * @param { { order_by : String, sort_order : String } } newSortPayload
     */
    _prepareSortPayload( newSortPayload ){
        let payload                     =   this._onBuildSortPayload( newSortPayload );
        if  ( !payload || !payload.hasOwnProperty( 'order_by' ) || !payload.hasOwnProperty( 'sort_order' ) ){
            if  ( this.showDebugData ){
                console.error( `${ this.__getViewId() }::_prepareSortPayload got no valid data to build filter!` );
            }
            return;
        }
        payload                         =   Object.assign( {}, this._currentFilter, payload );
        if  ( this.showDebugData ){
            console.debug( `${ this.__getViewId() }::_prepareSortPayload - filter payload will be`, payload );
        }
        return                          payload;
    }

    /**
     * @param {?Number} nextPage forces next page specified value
     * @returns 
     */
    _prepareMorePayload( nextPage = undefined ){
        let payload                     =   this._onBuildMorePayload( {
            next_page                       :   nextPage ?? this._paginatorSelectNextPage(),
            max_amount                      :   this._paginatorState.itemsPerPage,
            page_key                        :   this._paginatorState.pageParameter
        } );
        if  ( !payload || !payload.hasOwnProperty( 'next_page' ) || !payload.hasOwnProperty( 'max_amount' ) || !payload.hasOwnProperty( 'page_key' ) ){
            if  ( this.showDebugData ){
                console.error( `${ this.__getViewId() }::_prepareMorePayload got no valid data to build filter!` );
            }
            return;
        }
        payload                         =   Object.assign( {}, this._currentFilter, {
            [ payload[ 'page_key' ] ]       :   payload[ 'next_page' ],
            'max_amount'                    :   payload[ 'max_amount' ]
        } );
        if  ( this._sort.enable ){
            Object.assign( payload, {
                sort_order                  :   this._sort.sort_order,
                order_by                    :   this._sort.order_by
            } );
        }
        if  ( this.showDebugData ){
            console.debug( `${ this.__getViewId() }::_prepareMorePayload - filter payload will be`, payload );
        }
        return                          payload;
    }

    _prepareSearchPayload( newSearchData ){
        // when preparing a search payload, we need to explicitly bind sort, if any
        if  ( this._sort.enable ){
            Object.assign( newSearchData, {
                sort_order                  :   this._sort.sort_order,
                order_by                    :   this._sort.order_by
            } );
        }
        let payload                     =   this._onBuildSearchPayload( newSearchData );
        return                          payload;
    }

    //#endregion

    //#region Button events

    /**
     * fires when user clicks sort
     *
     * @param {JQuery.Event} event
     */
    _onSortButtonClick( event ){
        event.preventDefault();
        if  ( !this._sortSupported ){
            if  ( this.showDebugData ){
                if  ( this.showDebugData ){
                    console.debug( `${ this.__getViewId() }::_onSortButtonClick : sort is not supported by this table!` );
                }
            }
            return;
        }
        if  ( this.showDebugData ){
            console.debug( `${ this.__getViewId() }::_onSortButtonClick : current sort state is`, this._sort );
        }
        let currentSorted               =   event.target.dataset.sort == '1';
        let newSortData                 =   {
            order_by                        :   event.target.id,
            sort_order                      :   undefined,
        };
        newSortData.sort_order          =   event.target.classList.contains( "reverse" ) ? "DESC" : "ASC";
        if  ( !( newSortData = this._prepareSortPayload( newSortData ) ) ){
            throw                       new Error( `${ this.__getViewId() }::_onSortButtonClick : no new sort data to use` );
        };
        this._sendSort( newSortData );
    }

    /**
     * fires when user clicks more
     *
     * @param {JQuery.Event} event
     */
    _onMoreButtonClick( event ){
        event.preventDefault();
        if  ( ( this._paginatorState.paginatorMode === PAGINATOR_MODE_ARRAY ) && ( this._paginatorState.itemsInView.length < this._paginatorState.itemsLoaded ) ){
            // cut any loaded items to me without loading any shit
            let items                   =   this._$hiddenZone.children();
            if  ( !items.length ){
                throw                   new Error( `${ this.__getViewId() }::_onMoreButtonClick : array paginator requested some data, but there is nothing in the hidden zone` );
            }
            let nextPage                =   this._paginatorSelectNextPage();
            if  ( isNaN( nextPage ) || !this._paginatorState.pagesLoaded.hasOwnProperty( nextPage ) ){
                throw                   new Error( `${ this.__getViewId() }::_onMoreButtonClick : array paginator can not get next page to use` );
            }
            items.slice( 0, this._paginatorState.itemsPerPage ).detach().appendTo( this._$inser );
            this._paginatorState.pagesLoaded[ nextPage ]    =   true;
            this._paginatorState.pageCurrent    =   nextPage;
            this._calculateItemsInView();
            this._checkAfterPageLoaded();
            return;
        }
        // try to check if we already have next page loaded
        let candidatePage               =   Number( this._paginatorState.pageCurrent );
        candidatePage                   =   ( candidatePage === this._paginatorState.pagesCount ) ? 1 : candidatePage + 1;
        if  ( this._checkPageLoaded( candidatePage ) ){
            if  ( this._showDebugData ){
                console.debug( `${ this.__getViewId() }::_onMoreButtonCick : page ${candidatePage} is already loaded, updating view` );
            }
            this._switchPage( candidatePage, false );
            this._paginatorUpdate_SetCurrentPage( candidatePage );
        } else {
            this._sendMoreFilter( this._prepareMorePayload() );
        }
    }

    /**
     * Switches elements which have got this page number ( shows and enables only that, hides others )
     */
    _switchPage( pageNum, exclusive = true ){
        let selectors                   =   this.__getSelectors();
        let itemSelector                =   selectors?.tableAdClass ?
            `${selectors.tableItem}:not(.${selectors.tableAdClass})` :
            `${selectors.tableItem}`
        ;
        if  ( !exclusive ){ // move current page to end of view
            let currentPageItems        =   this._$inser.children( `[data-paginator-page-num='${pageNum}']` );
            currentPageItems.detach().appendTo( this._$inser );
        }
        this._$inser.children( itemSelector ).each( ( i, el ) => {
            if  ( el.dataset?.paginatorPageNum == pageNum ){
                $( el ).removeClass( "d-none" );
            } else if ( exclusive ) {
                $( el ).addClass( "d-none" );
            }
        } );
    }

    /**
     * @param {JQuery.Event} event
     */
    _onPageButtonClick( event ){
        if  ( !this._$inser )
            return;
        if  ( !event.currentTarget?.dataset?.page ){
            throw                       new Error( `${ this.__getViewId() }::_onPageButtonClick : no page parameter to use!` );
        }
        if  ( true ){
            event.preventDefault();
            // check if there is a page loaded. if so, disable submit
            let pageNum                 =   Number( event.currentTarget.dataset.page );
            if  ( this._checkPageLoaded( pageNum ) ){
                if  ( this._showDebugData ){
                    console.debug( `${ this.__getViewId() }::_onPageButtonCick : page ${pageNum} is already loaded, updating view` );
                }
                this._switchPage( pageNum );
                this._paginatorUpdate_SetCurrentPage( pageNum );
            } else {
                let payload             =   this._prepareMorePayload( pageNum );
                if  ( this._showDebugData ){
                    console.debug( `${ this.__getViewId() }::_onPageButtonCick : user selected a page with`, payload );
                }
                this._sendMoreFilter( payload, true );
            }
        } else {
            document.cookie             =   PAGE_CHANGE_COOKIE_NAME + "=" + btoa( JSON.stringify( this._currentFilter ) ) + ";path=/";
        }
    }

    //#endregion

    //#region Internal callbacks

    _onSortBefore( payloadClient ){
        if  ( typeof this._pfnOnBeforeSendSortinFilter === 'function' )
            if  ( !this._pfnOnBeforeSendSortinFilter( this, payloadClient ) )
                return                  false;
        return                          true;
    }

    /**
     * @param {{
     *  childStatus     :   Boolean,
     *  originalData    :   Object,
     *  filterData      :   Object,
     *  sortData        :   { order_by : String; sort_order : String }
     * }} data
     */
    _onSortSuccess( data ){
        if  ( !data?.childStatus || !data?.originalData ){
            throw                       new Error( `${ this.__getViewId() }::_onSortSuccess : bad status` );
        }
        this._currentFilter             =   data.filterData;
        if  ( typeof data?.sortData === 'object' ){
            Object.assign( this._sort, data.sortData );
            if  ( this._$header ){
                let sortColumn          =   this._$header.find( "[data-sort=1]" );
                if  ( sortColumn.length ){
                    if  ( sortColumn[ 0 ].id != this._sort.order_by ){
                        sortColumn.attr( 'data-sort', 0 );
                        if  ( ( sortColumn = this._$header.find( `.sort-by#${this._sort.order_by}` ) ).length ){
                            if  ( this._sort.sort_order === "ASC" ){
                                if  ( !sortColumn.hasClass( "reverse" ) ){
                                    sortColumn.addClass( "reverse" );
                                }
                            } else {
                                if  ( sortColumn.hasClass( "reverse" ) ){
                                    sortColumn.removeClass( "reverse" );
                                }
                            }
                            sortColumn.attr( 'data-sort', 1 );
                        } else {
                            if  ( this.showDebugData ){
                                console.debug( `${ this.__getViewId() }::_onSortSuccess : can not find column #${this._sort.order_by}` );
                            }
                        }
                    } else {
                        sortColumn.toggleClass( "reverse" );
                    }
                    if  ( this.showDebugData ){
                        console.debug( `${ this.__getViewId() }::_onSortSuccess : sort view changed to #${this._sort.order_by} ${this._sort.sort_order}` );
                    }
                }
            }
        }
        this._calculateItemsInView();
        return                          true;
    }

    _onSortFail( data ){
        return                          true;
    }

    _onSortAlways( data ){
        return                          true;
    }

    /**
     * internal. fires before send more event, got prepared filter payload as parameter.
     * @param {*} payloadClient
     * @returns
     */
    _onMoreBefore( isPageSwitch, payloadClient ){
        if  ( typeof this._pfnOnBeforeSendPageFilter === 'function' )
            if  ( !this._pfnOnBeforeSendPageFilter( this, payloadClient ) )
                return                  false;
        return                          true;
    }

    /**
     * @param {Boolean} isPageSwitch
     * @param {{childStatus:Boolean,originalData:Object,pageNumber:Number}} data
     */
    _onMoreSuccess( isPageSwitch, data ){
        if  ( !data?.childStatus || !data?.originalData || !data?.pageNumber ){
            throw                       new Error( `${ this.__getViewId() }::_onMoreSuccess : bad status` );
        }
        this._assignPageNumber( data.pageNumber );
        // update loaded items count
        let currentItemsInView          =   this._getItemsInView( false );
        this._paginatorUpdate( data.pageNumber,
            null,
            null,
            currentItemsInView !== null ? currentItemsInView.length : null,
            true
        );
        // shows only selected items if we are in pageSwitch 
        if  ( isPageSwitch ){
            if  ( this._showDebugData ){
                console.debug( `${ this.__getViewId() }::_onMoreSuccess : switching to ${data.pageNumber} after loading sequence` );
            }
            if  ( this._checkPageLoaded( data.pageNumber ) ){
                this._switchPage( data.pageNumber );
            } else { 
                throw                   new Error( `${ this.__getViewId() }::_onMoreSuccess : even though we have a page ${data.pageNumber} loaded, the paginator have not got it!` );
            }
        }
        this._calculateItemsInView();
        this._checkAfterPageLoaded();
    }

    _onMoreFail( isPageSwitch, data ){
        return;
    }

    _onMoreAlways( isPageSwitch, data ){
        if  ( typeof this._pfnOnAfterSendPageFilter === 'function' ){
            this._pfnOnAfterSendPageFilter( this, true, data, false );
        }
    }

    _onSearchBefore( payloadClient ){
        if  ( typeof this._pfnOnBeforeSendSearchFilter === 'function' )
            if  ( !this._pfnOnBeforeSendSearchFilter( this, payloadClient ) )
                return                  false;
        return                          true;
    }

    /**
     * @param { {
     *      childStatus         :   Boolean;
     *      originalData        :   Object;
     *      filterData          :   Object;
     *      pagesCount          :   Number;
     *      totalCount          :   Number;
     *      currentItemCount    :   Number;
     *      sortData            :   { order_by : String; sort_order : String }
     *  } } data
     */
    _onSearchSuccess( data ){
        if  ( !data?.childStatus || !data?.originalData ){
            throw                       new Error( `${ this.__getViewId() }::_onSearchSuccess : bad status` );
        }
        if  ( this.showDebugData ){
            console.debug( `AbstractListViewHelper::_onSearchDoneSuccess : data is`, data );
        }
        this._currentFilter             =   data.filterData;
        if  ( typeof data?.sortData === 'object' ){
            Object.assign( this._sort, data.sortData );
        }
        this._selectorsInit( this.__getDynamicSelectors() );
        this._paginatorArrayModeRecalc();
        this._paginatorUpdate( 1, data.pagesCount, data.totalCount, data.currentItemCount, true );
        this._calculateItemsInView();
        this._checkAfterPageLoaded();
        Object.keys( data.originalData ).forEach( ( key ) => {
            this._onBlockReceived( key, data.originalData[ [key] ] );
        } );
    }

    _onSearchFail( data ){
        return;
    }

    _onSearchAlways( data ){
        if  ( typeof this._pfnOnAfterSendSearchFilter === 'function' ){
            this._pfnOnAfterSendSearchFilter( this, true, data, false );
        }
    }

    /**
     * tells us when we get any block
     * @param {*} key
     * @param {*} data
     */
    _onBlockReceived( key, data ){
        if  ( this.showDebugData ){
            console.debug( `${this.__getViewId()}::_onBlockRecived : key "${key}" data is`, data );
        }
        if  ( typeof this._pfnOnBlockReceived === 'function' ){
            this._pfnOnBlockReceived( this, key, data );
        }
    }

    //#endregion

    /**
     * attach current stare and returns a copy of query
     * @param {*} dest
     * @returns
     */
    __attachCurrentState( dest ){
        return                          ( app?.currentState ) ? Object.assign( {},
                                        dest,
                                        {
                                            [ API_KEY_CURRENT_STATE ] : app.currentState
                                        } ) : Object.assign( {}, dest );
    }

    //#region Sumbmission

    /**
     *
     * @param {{sort_order : String; order_by : String}} newSearchFilter a search data to use
     */
    _sendSort( newSearchFilter ){
        let payloadClient               =   this.__attachCurrentState( {
            [ API_KEY_QUERY_MODE ]          :   API_QUERY_MODE_SORT,
            [ API_KEY_QUERY ]               :   Object.assign( {}, this._currentFilter, newSearchFilter, {
                                                    [ API_KEY_ITEMS ] : this._paginatorState.itemsInView
                                                } ),
            [ API_KEY_QUERY_BLOCKS ]        :   [ BLOCK_NAME_SEARCH ]
        } );
        if  ( this.showDebugData ){
            console.debug( `${ this.__getViewId() }::_sendSort : payload to send is`, payloadClient );
        }
        this._submitPayload( payloadClient,
            ( this._onSortBefore ).bind( this ),
            ( this._onSortSuccess ).bind( this ),
            ( this._onSortFail ).bind( this ),
            ( this._onSortAlways ).bind( this ) );
    }

    _sendMoreFilter( moreFilterData, isPageSwitch = false ){
        let payloadClient               =   this.__attachCurrentState( {
            [ API_KEY_QUERY_MODE ]          :   API_QUERY_MODE_PAGE,
            [ API_KEY_QUERY ]               :   moreFilterData,
            [ API_KEY_QUERY_BLOCKS ]        :   [ this._slotId ?? BLOCK_NAME_SEARCH ]
        } );
        if  ( this.showDebugData ){
            console.debug( `${ this.__getViewId() }::_sendMoreFilter : payload to send is `, payloadClient );
        }
        this._submitPayload( payloadClient,
            ( this._onMoreBefore ).bind( this, isPageSwitch ),
            ( this._onMoreSuccess ).bind( this, isPageSwitch ),
            ( this._onMoreFail ).bind( this, isPageSwitch ),
            ( this._onMoreAlways ).bind( this, isPageSwitch ) );
    }

    /**
     * @param {Object} searchData can be empty, if form is assigned, otherwice you must provide some data to use
     */
    sendSearch( searchData = undefined ){
        if  ( !searchData ){
            if  ( !this._$form ){
                throw                   new Error( `${ this.__getViewId() }::sendSearch : no data and form specified` );
            }
            searchData                  =   this._$form.find( 'select,input,textarea' ).filter( ( i, e ) => { return ( e.value != '' && e.type != 'hidden' ); } ).serializeJSON();
            if  ( searchData.hasOwnProperty( this._$form.attr( "name" ) ) ){
                searchData              =   searchData[ this._$form.attr( "name" ) ];
            }
        }
        searchData                      =   this._prepareSearchPayload( searchData );
        if  ( typeof searchData !== 'object' ){
            throw                       new Error( `${ this.__getViewId() }::sendSearch : _prepareSearchPayload has no searchData to send` );
        }
        let payloadClient               =   this.__attachCurrentState( {
            [ API_KEY_QUERY_MODE ]          :   API_QUERY_MODE_SEARCH,
            [ API_KEY_QUERY ]               :   searchData,
            [ API_KEY_QUERY_BLOCKS ]        :   [ BLOCK_NAME_SEARCH ]
        } );
        if  ( this.showDebugData ){
            console.debug( `${ this.__getViewId() }::_sendSearch : payload to send is`, payloadClient );
        }
        this._submitPayload( payloadClient,
            ( this._onSearchBefore ).bind( this ),
            ( this._onSearchSuccess ).bind( this ),
            ( this._onSearchFail ).bind( this ),
            ( this._onSearchAlways ).bind( this ) );
    }

    /**
     * internal. use _submitPayload instead.
     * @param { function( Object|null, Boolean ) } cb
     */
    _sendPayload( url, method, payload, cb = undefined ){
        if  ( !url || !method ){
            throw                       new Error( `${ this.__getViewId() }::_sendPayload : no mandratory parameters was given` );
        }
        $.ajax( url, {
            method : method,
            dataType : "json",
            contentType : "application/json; charset=utf-8",
            data : JSON.stringify( payload ),
            cache : false
        } ).done( ( data, textStatus, jqXHR ) => {
            if  ( this.showDebugData ){
                console.debug( `${ this.__getViewId() }::_sendPayload : done() reponse is `, data );
            }
            if  ( typeof cb === "function" ){
                cb( data?.payload, true );
            };
        } ).fail( /** @param {JQuery.jqXHR} jqxhr */( jqxhr, data, status ) => {
            if  ( this.showDebugData ){
                if  ( jqxhr?.responseJSON?.errors ){
                    console.debug( `${ this.__getViewId() }::_sendPayload : fail() tells us `, jqxhr?.responseJSON?.errors, ` with status `, jqxhr?.responseJSON?.status );
                } else {
                    console.debug( `${ this.__getViewId() }::_sendPayload : fail() reponse is `, jqxhr, data, status );
                }
            }
            if  ( typeof cb === "function" ){
                cb( jqxhr, false );
            };
        } ).always( () => {
            if  ( typeof cb === "function" ){
                cb( null, null );
            };
        } );
    }

    //#endregion

    /**
     * One function for sending an event to server.
     *
     * @param {Object} payload
     * @param {function( Object ) : Boolean} cbPreSubmit
     * @param {function( Object ) : Boolean} cbSuccess
     * @param {function( Object ) : Boolean} cbFail
     * @param {function( Object ) : Boolean} cbAlways
     */
    _submitPayload( payload, cbPreSubmit, cbSuccess, cbFail, cbAlways = undefined ){
        if  ( typeof cbPreSubmit === 'function' ){
            if  ( !cbPreSubmit( payload ) ){
                return;
            }
        }
        this._sendPayload( this._url, this._method, payload, ( ( data, status ) => {
            if  ( status === true ){
                if  ( typeof cbSuccess === "function" ){
                    cbSuccess( data );
                }
            } else if ( status === false ) {
                if  ( typeof cbFail === "function" ){
                    cbFail( data );
                }
            } else {
                if  ( typeof cbAlways === "function" ){
                    cbAlways( data );
                }
            }
        } ).bind( this ) );
    }

    //#endregion

    //#region Pre and Post events

    /**
     * internal. fires when we build more payload to send, returns valid data to attach to filter
     *
     *
     * @param { { next_page : Number, max_amount : Number, page_key : string } } moreData
     * @returns { { next_page : Number, max_amount : Number, page_key : string } }
     */
    _onBuildMorePayload( moreData ){
        return                          ( typeof this._pfnOnBuildMorePayload === 'function' ) ?
                                            this._pfnOnBuildMorePayload( this, moreData, this._currentFilter ) :
                                            moreData;
    }

    /**
     * @param { { order_by : String, sort_order : String } } sortData
     */
    _onBuildSortPayload( sortData ){
        return                          ( typeof this._pfnOnBuildSortPayload === 'function' ) ?
                                            this._pfnOnBuildSortPayload( this, sortData, this._currentFilter ) :
                                            sortData;
    }

    /**
     * internal. must return valid search data to use
     */
    _onBuildSearchPayload( searchData ){
        return                          ( typeof this._pfnOnBuildSearchPayload === 'function' ) ?
                                            this._pfnOnBuildSearchPayload( this, searchData ) :
                                            searchData;
    }

    //#endregion

    //#region Translation

    _translateLevel( levelBase, data ){
        let currentNodeName;
        Object.entries( data ).forEach( ( [ key, value ] ) => {
            currentNodeName             =   ( levelBase ) ? levelBase + "." + key : key;
            if  ( typeof value === 'object' ){
                if  ( Array.isArray( value )  ){
                    if  ( this._translation.fields.includes( currentNodeName ) ){
                        for ( let index = 0; index <= value.length; index++ ){
                            if  ( this._translation.dictionary.hasOwnProperty( value[ index ] ) ){
                                value[ index ] = this._translation.dictionary[ value[ index ] ];
                            }
                        }
                        data[ key ]     =   value;
                    }
                } else {
                    if  ( value != null ){
                        this._translateLevel( currentNodeName, value );
                    }
                }
            } else {
                if  ( this._translation.fields.includes( currentNodeName ) ){
                    data[ key ]         =   this._translation.dictionary[ value ];
                }
            }
        } );
    }

    /**
     * @param {*} item
     */
    _translateItem( item ){
        if  ( this._translationSupported ){
            if  ( Array.isArray( item ) ){
                item.forEach( ( subItem ) => { this._translateLevel( null, subItem ); } );
            } else {
                this._translateLevel( null, item );
            }
        }
    }

    //#endregion
}