const AbstractListViewHelper            =   require( '../AbstractListViewHelper' );

export class ArticleViewHelperClass extends AbstractListViewHelper.AbstractListViewHelper{

    __getViewId(){
        return                          "ArticleViewHelperClass";
    }

    __getSelectors( selector = undefined ){
        let selectors                   =   {
            inser                           :   ".articles-listing-main__row",
            footer                          :   ".tablemax__footer-button",
            footerMoreButton                :   "label.button#search-results-paginator-show-more",
            footerMoreButtonLoader          :   "label.button#search-results-paginator-show-more > .button_loader",
            footerPagination                :   "ul.paging-list",
            tableItem                       :   "a.articles__item-normal",
            tableAdClass                    :   "ad",
            footerPaginationItem            :   "a.paging-list__link",
        };
        return                          selector ? 
            ( selectors[ selector ] ?? null ) :
            selectors;
    }

    __getDynamicSelectors(){
        return                          [
            "inser",
            "footer",
            "footerMoreButton"
        ];
    }

    constructor( table, form = undefined ){
        super( $( table ), ( form ) ? $( form ) : undefined );
        if  ( !this._$table.length ){
            throw                       new Error( `${this._viewId}::constructor : no data to instantiate` );
        }
        if  ( this._showDebugData ){
            console.debug( `${this._viewId} : start` );
        }
        this._afterConstruction();        
    }

    _onMoreBefore( isPageSwitch, payloadClient ){
        if  ( super._onMoreBefore( isPageSwitch, payloadClient ) ){
            this._$inser.addClass( 'list-review_loading' );
            this._$footer.addClass( 'disabled' );
            return                          true;
        }
        return                              false;
    }

    _onMoreSuccess( isPageSwitch, data ){
        if  ( !data?.search?.content ){
            throw                       new Error( `${ this._viewId }::_onMoreSuccess : no search block` );
        }
        if  ( typeof this._handlebars?.sort !== "function" ){
            throw                       new ( `${ this._viewId }::_onMoreSuccess : no valid template to view data` );
        }
        if  ( this._showDebugData ){
            console.debug( `${ this._viewId }::_onMoreSuccess : passed data is `, data );
        }
        let content                     =   data.search.content;
        if  ( this._advertisementSupported ){
            content                     =   Object.assign( {}, content, { 
                advertisement : Object.assign( {}, this._advertisement, { 
                    template                :   this._handlebars.ad 
                } ) 
            } );
        }
        if  ( this._translationSupported && Array.isArray( content.payload ) ){
            content.payload.forEach( ( item ) => {
                this._translateItem( item );
            } );
        }
        try{
            content                     =   this._handlebars.sort( content );
        } catch ( e ) {
            throw                       new Error( `${ this._viewId }::_onMoreSuccess : template compile exception`, e );
        }
        this.__performContentInsert( content, data.search.content.pagination.current );
        // this._$inser.append( content );
        super._onMoreSuccess( isPageSwitch, { 
            childStatus                 :   true,
            originalData                :   data,
            pageNumber                  :   data.search.content.pagination.current,
        } );
    }

    _onMoreAlways( isPageSwitch, data ){
        super._onMoreAlways( isPageSwitch, data );
        this._$inser.removeClass( 'list-review_loading' );
        this._$footer.removeClass( 'disabled' );
    }

}