
/** various api constants that is used for payload api */

export const API_KEY_QUERY              =   "query";
export const API_KEY_QUERY_MODE         =   "queryMode";
export const API_KEY_QUERY_BLOCKS       =   "queryBlocks";
export const API_KEY_QUERY_ADDITIONAL   =   "additionalBlocks";
export const API_KEY_CURRENT_STATE      =   "currentState";
export const API_KEY_VIEW_MODE          =   "viewMode";
export const API_KEY_ITEMS              =   "items";
export const API_KEY_EXTRA_TAGS         =   "extraTags";

export const API_QUERY_MODE_SEARCH      =   "qmSearch";
export const API_QUERY_MODE_SORT        =   "qmSort";
export const API_QUERY_MODE_PAGE        =   "qmPage";
export const API_QUERY_MODE_RATING      =   "qmRating";
export const API_QUERY_MODE_FEEDBACK    =   "qmFeedback";
export const API_QUERY_MODE_CALCULATE   =   "qmCalculate";
export const API_QUERY_MODE_GET_DATA    =   "qmGetData";
export const API_QUERY_MODE_POST_CREQ   =   "qmPostCreditRequest";

export const API_QUERY_ADDITIONAL_HEADING   =   "headings";
export const API_QUERY_ADDITIONAL_THEAD     =   "thead";

export const API_QUERY_MODE             =   [
    API_QUERY_MODE_SEARCH,
    API_QUERY_MODE_SORT,
    API_QUERY_MODE_PAGE,
    API_QUERY_MODE_RATING
];

export function PayloadBuilder_IncludeCurrentState( dest ){
    return                              app?.currentState ? Object.assign( dest, {
        [ API_KEY_CURRENT_STATE ]           : app.currentState
    } ) : dest;
}

export function PayloadBuilder_MakePayload( queryMode, queryBlocks, additionalBlocks, filter, extraTags, includeCurrentState = false ){
    let out                             =   {
        [ API_KEY_QUERY_MODE ]              :   queryMode,
        [ API_KEY_QUERY_BLOCKS ]            :   queryBlocks,
        [ API_KEY_QUERY_ADDITIONAL ]        :   additionalBlocks,
        [ API_KEY_QUERY ]                   :   filter,
        [ API_KEY_EXTRA_TAGS ]              :   extraTags,
    };
    if  ( includeCurrentState ){
        out                             =   PayloadBuilder_IncludeCurrentState( out );
    };
    return                              out;
}

async function AjaxPayload_Native( input, init ){
    let result                          =   await fetch( input, init ).then( 
        ( response ) => {
            return                      response.json().then( ( json ) => { return { response : response, json : json }; } );
        }
    ).then( ( resolved ) => {
        return                          resolved;
    } );
    return                              await result;
}


export function PayloadBuilder_SubmitPayload( url, method, payload, pfnOnSuccess, pfnOnFail, pfnOnAlways ){
    // AjaxPayload_Native( url, {
    //     method                          :   method ?? "POST",
    //     body                            :   JSON.stringify( payload ), 
    //     headers                         :   {
    //         'Accept'                        :   "application/json",
    //         'Content-Type'                  :   "application/json",
    //         'X-Requested-With'              :   'XMLHttpRequest'
    //     }
    // } ).then( ( r ) => {
    //     if  ( r.response.ok ){
    //         if  ( typeof pfnOnSuccess === 'function' ){
    //             pfnOnSuccess( r.json )
    //         }
    //     } else {
    //         if  ( r.json?.status && r.json?.errors ){
    //             console.error( "PayloadBuilder_SubmitPayload : server request to ", url, " returned follwng error : ", r.response.status, " ( ", r.json.status,  " ) = ", r.json.errors );
    //         }
    //         if  ( typeof pfnOnFail === 'function' ){
    //             pfnOnFail( false, r );
    //         }
    //     }
    //     if  ( typeof pfnOnAlways === 'function' ){
    //         pfnOnAlways();
    //     }
    // } );

    $.ajax( {
        url : url,
        method: method,
        dataType: 'json',
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify( payload ),
    } ).done( ( response ) => {
        if  ( typeof pfnOnSuccess === 'function' ){
            pfnOnSuccess( response );
        } else {
            console.error( 'PayloadBuilder_SubmitPayload : no cbSuccess' );
        }
    } ).fail( /** @param {JQuery.jqXHR} jqxhr */( jqxhr, data, status ) => { 
        if  ( jqxhr?.responseJSON?.errors ){
            console.debug( `PayloadBuilder_SubmitPayload : fail() tells us `, jqxhr?.responseJSON?.errors, ` with status `, jqxhr?.responseJSON?.status );
        } else {
            console.debug( `PayloadBuilder_SubmitPayload : fail() reponse is `, jqxhr, data, status );
        }
        if  ( typeof pfnOnFail === 'function' ){
            pfnOnFail( jqxhr, data, status );
        }
    } ).always( () => {
        if  ( typeof pfnOnAlways === 'function' ){
            pfnOnAlways();
        } 
    } );
}