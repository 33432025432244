import { WidgetStack_CreateWidget } from "./common/wigetStack";
import { ArticleWidgetClass } from "./components/article/articleWidgetClass";

const   ContainerSelector               =   ".ArticleWidgetContainer";

// window.addEventListener( "DOMContentLoaded", function( event ) {
//     let windowCounter = 0;
    console.debug( 'ArticleWidgetClass : start' );
    WidgetStack_CreateWidget( ArticleWidgetClass, ContainerSelector, 'ArticleWidgetClass' );
//     $( ContainerSelector ).each( ( index, element ) => {
//         if  ( window[ "ArticleWidgetClass" + String( windowCounter ) ] )
//             return;
//         let inst                        =   new ArticleWidgetClass( element );
//         window[ "ArticleWidgetClass" + String( windowCounter ) ] = inst;
//         $( element ).data( "ArticleWidgetClass", inst );
//         console.debug( 'ArticleWidget : registered ArticleWidgetClass on', element );
//         windowCounter++;
//     } );
// } );